import React from "react";

const Navbar = ({ item }) => {
  if (item === "items") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Navbar Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Navbar Items</h1>
        <div className="pt-5">
          <p className="text-sm">Use this panel to add/edit/arrange navbar items.</p>

          <p className="text-sm mt-4"><span className="font-semibold">Dynamic item:</span> <span className="text-gray-600">Built-in navbar items in phpBB script</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Custom item:</span> <span className="text-gray-600">A special item, requires adding a label and link.</span></p>

          <p className="font-medium mt-5">Navbar Items:</p>
          <div className="md:grid grid-cols-3 gap-2">
            <div className="text-center">
              <img className="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/new-item.png" alt="New navbar item" />
              <p className="text-center text-sm font-medium text-blue-600 mt-3">Add new item</p>
            </div>
            <div className="text-center">
              <img className="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/edit-item.png" alt="Edit navbar item" />
              <p className="text-center text-sm font-medium text-blue-600 mt-3">Edit items</p>
            </div>
            <div className="text-center">
              <img className="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/arrange-item.png" alt="Arrange navbar item" />
              <p className="text-center text-sm font-medium text-blue-600 mt-3">Arrange items (Dragging)</p>
            </div>
          </div>

          <p className="font-medium mt-10">Result:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/navbar-items.png" alt="Navbar items" />

        </div>
      </div>
    )
  }
  else if (item === "fixed-navbar") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Navbar Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Fixed Navbar</h1>
        <div className="pt-5">
          <p className="text-sm">Use this option to pin the navigation bar to the top of the page with scrolling.</p>

          <p className="font-medium mt-5">Example:</p>
          <video className="rounded-xl shadow border mt-3 w-auto mx-auto" controls>
            <source src="./assets/images/fixed-navbar.mp4"></source>
          </video>
        </div>
      </div>
    )
  }
  else if (item === "login") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Navbar Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Quick Login Dropdown</h1>
        <div className="pt-5">
          <p className="text-sm">Show the quick login drop-down menu when you click the login button.</p>
          <p className="text-sm text-blue-600 mt-2">* Note: If the option is not activated, when you click on the login button, you will be taken to the login page.</p>
          <p className="font-medium mt-5">Example:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/quick-login.png" alt="Quick Login Dropdown" />
        </div>
      </div>
    )
  }
}

export default Navbar;
