import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const Header = ({ updateState }) => {
  const changeState = () => {
    updateState();
  };

  return (
    <header className="fixed top-0 z-20 w-full border-b text-sm py-6 lg:px-4 px-6 backdrop-blur bg-white/85 supports-backdrop-blur:bg-white/60">
      <nav className="max-w-[85rem] w-full mx-auto sm:flex sm:items-center sm:justify-between" aria-label="Global">
        <div className="flex gap-4">
          <button type="button" onClick={changeState} className="lg:hidden size-8 text-sm flex justify-center items-center font-semibold rounded-lg border text-gray-800 hover:bg-gray-100" aria-label="Toggle navigation">
            <Icon icon="lucide:menu" width={16} height={16} mode="svg" />
          </button>

          <a className="flex-none text-xl font-semibold flex items-center gap-2" href="./">
            <img src="./assets/images/logo.svg" className="h-6 w-full" />
            <sub className="text-sm">Documentation</sub>
          </a>
        </div>
        <div className="flex flex-row items-center gap-5 mt-5 sm:justify-end sm:mt-0 sm:ps-5">
          <a className="font-medium text-gray-600 hover:text-blue-600" href="https://leenoz.com/zeina">Home</a>
          <a className="font-medium text-gray-600 hover:text-blue-600" href="https://demo.leenoz.com/zeina">Demo</a>
          <a className="font-medium text-blue-600" href="https://leenoz.com/zeina#price">Download</a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
