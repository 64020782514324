import React from "react";

const Footers = ({ item }) => {
  if (item === "items") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Footer Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Footer Items</h1>
        <div className="pt-5">
          <p className="text-sm">Use this panel to add/edit/arrange footer items.</p>

          <p className="text-sm mt-4"><span className="font-semibold">Dynamic item:</span> <span className="text-gray-600">Built-in items in phpBB script</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Custom item:</span> <span className="text-gray-600">A special item, requires adding a label and link.</span></p>

          <p className="font-medium mt-5">Footer Items:</p>
          <div className="md:grid grid-cols-3 gap-2">
            <div className="text-center">
              <img className="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/add-footer-item.png" alt="New footer item" />
              <p className="text-center text-sm font-medium text-blue-600 mt-3">Add new item</p>
            </div>
            <div className="text-center">
              <img className="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/edit-footer-item.png" alt="Edit footer item" />
              <p className="text-center text-sm font-medium text-blue-600 mt-3">Edit items</p>
            </div>
            <div className="text-center">
              <img className="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/arrange-footer-items.png" alt="Arrange footer item" />
              <p className="text-center text-sm font-medium text-blue-600 mt-3">Arrange items (Dragging)</p>
            </div>
          </div>

          <p className="font-medium mt-10">Result:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/footer-items.png" alt="logo tutorial 1" />

        </div>
      </div>
    )
  }
  else if (item === "social") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Footer Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Social Media Items</h1>
        <div className="pt-5">
          <p className="text-sm">Exactly as in Navbar and Footer, items can be added, modified, or arranged.</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/social.png" alt="logo tutorial 1" />
      
          <p className="font-medium mt-10">Result:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/footer-social.png" alt="logo tutorial 1" />
        </div>
      </div>
    )
  }
  else if (item === "copyright") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Footer Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Custom Copyright</h1>
        <div className="pt-5">
          <p className="text-sm">You can add your own copyright if you want.</p>

          <p className="font-medium mt-5">Tutorial:</p>
          <p className="text-gray-600 text-sm mt-1">Copy and paste this code into your custom copyright editor and save the settings.</p>

          <pre className="mt-3 bg-gray-100 border border-gray-200 text-sm leading-6 flex overflow-auto rounded-xl">
            <code className="p-5 whitespace-break-spaces"><div><span className="text-red-500">&amp;copy;</span> 2024 <span className="text-slate-800">&lt;</span><span className="text-red-500">a</span> <span className="text-violet-800">href</span><span className="text-slate-800">=</span><span className="text-cyan-700">"https://leenoz.com"</span><span className="text-slate-800">&gt;</span>Leenoz.com<span className="text-slate-800">&lt;/</span><span className="text-red-500">a</span><span className="text-slate-800">&gt;</span></div></code>
          </pre>

          <p className="font-medium mt-7">Result:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/copyright.png" alt="Copyright" />
        </div>
      </div>
    )
  }
}

export default Footers;

