import React from "react";

const General = ({ item }) => {
  if (item === "logo") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Logo</h1>
        <div className="pt-5">
          <p className="text-sm"><span className="font-semibold">Accepted extensions:</span> <span className="text-gray-600">png, jpeg, jpg, gif, svg, webp.</span></p>
          <p className="text-gray-600 text-sm mt-4">We believe SVG files are the best choose for website logo due to their high resolution, small size and support for transparent backgrounds.</p>
          <p className="text-gray-600 text-sm mt-0.5">You can also change the logo color with the theme color as in the following tutorial.</p>
          
          <p className="font-medium mt-5">Tutorial:</p>
          <p className="text-gray-600 text-sm mt-1">Save this code as SVG file and upload it.</p>

          <pre className="mt-3 bg-gray-100 border border-gray-200 text-sm leading-6 flex overflow-auto rounded-xl">
            <code className="p-5 whitespace-break-spaces"><div><span className="text-slate-800">&lt;</span><span className="text-red-500">svg</span> <span className="text-violet-800">version</span><span className="text-slate-800">=</span><span className="text-cyan-700">"1.1"</span> <span className="text-violet-800">xmlns</span><span className="text-slate-800">=</span><span className="text-cyan-700">"http://www.w3.org/2000/svg"</span> <span className="text-violet-800">viewBox</span><span className="text-slate-800">=</span><span className="text-cyan-700">"0 0 512 512"</span><span className="text-slate-800">&gt;</span></div><div>&nbsp;&nbsp;<span className="text-slate-800">&lt;</span><span className="text-red-500">style</span><span className="text-slate-800">&gt;</span></div><div>&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-violet-500">path.logo-path</span> <span className="text-slate-800">&#123;</span> fill: <span className="text-red-500">rgb</span>(<span className="text-red-400">var</span>(--color-primary)) <span className="text-slate-800">&#125;</span></div><div>&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-red-500">.dark</span> <span className="text-violet-500">path.logo-path</span> <span className="text-slate-800">&#123;</span> fill: <span className="text-red-500">rgb</span>(<span className="text-red-400">var</span>(--color-primary-dark)) <span className="text-slate-800">&#125;</span></div><div>&nbsp;&nbsp;<span className="text-slate-800">&lt;/</span><span className="text-red-500">style</span><span className="text-slate-800">&gt;</span></div><div>&nbsp;&nbsp;<span className="text-slate-800">&lt;</span><span className="text-red-500">path</span> <span className="text-violet-800">class</span><span className="text-slate-800">=</span><span className="text-cyan-700">"logo-path"</span> <span className="text-violet-800">d</span><span className="text-slate-800">=</span><span className="text-cyan-700">"M472.609,336H339c-8.828,0-16.008-7.156-16.008-16
	c0-8.828,7.18-15.984,16.008-15.984h128.227c6.492,0,11.773-5.383,11.773-12c0-0.969-0.258-1.867-0.469-2.766
	c-12.578-37.922-47.594-65.242-89.016-65.242c-51.992,0-94.125,42.984-94.125,95.992c0,53.031,42.133,96.008,94.125,96.008
	c32,0,60.219-16.336,77.227-41.219l0.039,0.016c2.891-3.875,7.461-6.422,12.664-6.422c8.719,0,15.805,7.086,15.805,15.805
	c0,3.211-0.969,6.188-2.617,8.68c-0.219,0.328-0.469,0.594-0.703,0.898c-22.727,32.758-60.062,54.25-102.414,54.25
	c-69.32,0-125.523-57.32-125.523-128.016c0-70.68,56.203-128,125.523-128c59.055,0,108.461,41.656,121.828,97.703
	c0.32,2.039,0.5,4.156,0.5,6.312C511.844,318.102,494.281,336,472.609,336L472.609,336z M431.992,160.016H336
	c-8.844,0-16-7.176-16-16c0-8.844,7.156-16.004,16-16.004h95.992c8.852,0,16.008,7.16,16.008,16.004
	C448,152.84,440.844,160.016,431.992,160.016L431.992,160.016z M16,160.016c8.844,0,16,7.156,16,15.984c0,8.844-7.156,16-16,16
	c-8.828,0-16-7.156-16-16C0,167.172,7.172,160.016,16,160.016L16,160.016z M223,265.266c6.227,0,11.57,3.57,14.211,8.773
	c11.836,17.742,18.797,39.039,18.797,61.961c0,61.859-50.156,112.016-112.008,112.016h-24.008h-7.984H40
	c-22.078,0-40-17.922-40-40.008v-144c0-22.102,17.922-40,40-40h87.992h8c44.188,0,80.016-35.809,80.016-79.992
	c0-44.188-35.828-80.012-80.016-80.012h-23.984H48c-8.844,0-16,7.176-16,16.004v32.004c0,8.824-7.156,16-16,16
	c-8.828,0-16-7.176-16-16V72.004C0,49.922,17.922,32,40,32h72.008h7.984h16c61.859,0,112.016,50.16,112.016,112.016
	c0,53.582-37.727,98.164-88.008,109.156c0,0-10.531,2.836-16,2.836h-16.008h-15.984H44c-6.617,0-12,5.367-12,12v132
	c0,8.828,7.156,16,16,16h64.008H144c44.18,0,80.008-35.82,80.008-80.008c0-16.828-5.219-32.414-14.102-45.297l0.141-0.109
	c-1.898-2.633-3.047-5.844-3.047-9.344C207,272.422,214.156,265.266,223,265.266L223,265.266z"</span><span className="text-slate-800"> /&gt;</span></div><div><span className="text-slate-800">&lt;/</span><span className="text-red-500">svg</span><span className="text-slate-800">&gt;</span></div></code>
          </pre>

          <p className="font-medium mt-5">Result:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/logo-1.png" alt="logo tutorial 1" />
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/logo-2.png" alt="logo tutorial 2" />
          <br />
          <p className="text-gray-600 text-sm my-2">So you can change the color of the logo by adding a new class name to the "path" and adding CSS codes like this:</p>
          <pre className="mt-3 bg-gray-100 border border-gray-200 text-sm leading-6 flex overflow-auto rounded-xl">
            <code className="p-5 whitespace-break-spaces"><div><span className="text-slate-800">&lt;</span><span className="text-red-500">style</span><span className="text-slate-800">&gt;</span></div><div>&nbsp;&nbsp;<span className="text-violet-500">path.&lt;YOUR_CLASS_NAME&gt;</span> <span className="text-slate-800">&#123;</span> fill: <span className="text-red-500">rgb</span>(<span className="text-red-400">var</span>(--color-primary)) <span className="text-slate-800">&#125;</span></div><div>&nbsp;&nbsp;<span className="text-red-500">.dark</span> <span className="text-violet-500">path.&lt;YOUR_CLASS_NAME&gt;</span> <span className="text-slate-800">&#123;</span> fill: <span className="text-red-500">rgb</span>(<span className="text-red-400">var</span>(--color-primary-dark)) <span className="text-slate-800">&#125;</span></div><div><span className="text-slate-800">&lt;/</span><span className="text-red-500">style</span><span className="text-slate-800">&gt;</span></div></code>
          </pre>
        </div>
      </div>
    );
  }
  else if (item === "favicon") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Favicon</h1>
        <div className="pt-5">
          <p className="text-sm"><span className="font-semibold">Accepted extensions:</span> <span className="text-gray-600">png, jpeg, jpg, gif, svg, ico, webp.</span></p>
          <p className="text-gray-600 text-sm mt-4">A favicon is a small icon that serves as branding for your website as in the following image:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/favicon.png" alt="favicon example" />
        
          <p className="text-gray-600 text-sm mt-8">If you want your website to appear on the home screen of Android and iOS/iPadOS devices like this:</p>
          <div className="md:grid grid-cols-2 gap-2 mt-6 max-md:space-y-4">
            <div className="mx-auto">
              <img className="rounded-lg shadow border max-w-full w-fit max-h-56" src="./assets/images/favicon-android.png" alt="Boxed layout example" />
              <div className="mt-3 font-medium text-sm text-center text-blue-600">Android</div>
            </div>
            <div className="mx-auto">
              <img className="rounded-lg shadow border max-w-full w-fit max-h-56" src="./assets/images/favicon-apple.png" alt="Fluid layout example" />
              <div className="mt-3 font-medium text-sm text-center text-blue-600">iOS & iPadOS</div>
            </div>
          </div>
          <p className="text-gray-600 text-sm mt-8">You must use an image with a size of at least 256x256 in order to maintain quality during display. Once the image is uploaded, Zeina panel will crop the image to different sizes (16x16, 32x32, 48x48, 180x180, 192x192) to fit all devices and browsers.</p>
        </div>
      </div>
    );
  }
  else if (item === "layout") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Page Layout</h1>
        <div className="pt-5">
          <p className="text-sm"><span className="font-semibold">Boxed (Default):</span> <span className="text-gray-600">Fixed width, the content will be in the center.</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Fluid:</span> <span className="text-gray-600">Full width of your screen.</span></p>
          
          <p className="font-medium mt-5">Example:</p>
          <p className="text-gray-600 text-sm mt-1">In this example, we will use illustrative images to explain how it works.</p>

          <div className="md:grid grid-cols-2 gap-2 mt-4 max-md:space-y-4">
            <div className="mx-auto">
              <img className="rounded-lg shadow border max-w-full w-fit" src="./assets/images/boxed.png" alt="Boxed layout example" />
              <div className="mt-3 font-medium text-sm text-center text-blue-600">Boxed Layout</div>
            </div>
            <div className="mx-auto">
              <img className="rounded-lg shadow border max-w-full w-fit" src="./assets/images/fluid.png" alt="Fluid layout example" />
              <div className="mt-3 font-medium text-sm text-center text-blue-600">Fluid Layout</div>
            </div>
          </div>

          <p className="font-medium mt-5">Custom Page Width:</p>
          <p className="text-gray-600 text-sm mt-1">With this option you can set the page width (Boxed layout only).</p>
          
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/page-width.png" alt="Page width" />
        </div>
      </div>
    );
  }
  else if (item === "theme-mode") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Theme Mode</h1>
        <div className="pt-5">
          <p className="text-sm"><span className="font-semibold">Auto (Default):</span> <span className="text-gray-600">Allow member or visitor to change theme mode.</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Light:</span> <span className="text-gray-600">Make the theme mode light all the time.</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Dark:</span> <span className="text-gray-600">Make the theme mode dark all the time.</span></p>

          <br />
          <p className="font-medium">Light Mode:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/light-mode.png" alt="Light mode example" />
          <br />
          <br />
          <p className="font-medium">Dark Mode:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/dark-mode.png" alt="Dark mode example" />
        </div>
      </div>
    )
  }
  else if (item === "theme-color") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Theme Color</h1>
        <div className="pt-5">
          <p className="text-gray-600 font-medium text-sm mt-1">The base theme color:</p>
          <p className="text-gray-600 text-sm mt-1">(<span style={{ color: "rgb(37, 99, 235)" }}>Blue</span>, <span style={{ color: "rgb(185, 28, 28)" }}>Red</span>, <span style={{ color: "rgb(21, 128, 61)" }}>Green</span>, <span style={{ color: "rgb(194, 65, 12)" }}>Orange</span>, <span style={{ color: "rgb(15, 118, 110)" }}>Teal</span>, <span style={{ color: "rgb(79, 70, 229)" }}>Indigo</span>, <span style={{ color: "rgb(182, 30, 201)" }}>Fuchsia</span>, <span style={{ color: "rgb(205, 35, 110)" }}>Pink</span>)</p>
          <p className="text-gray-600 font-medium text-sm mt-3">Custom color:</p>
          <p className="text-gray-600 text-sm mt-1">This option allows you to choose a special color.</p>
          <br />
          <p className="font-medium">Example:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/colors.png" alt="Theme colors example" />
        </div>
      </div>
    )
  }
  else if (item === "font") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Font Style</h1>
        <div className="pt-5">
          <p className="text-sm">We offer you a large collection of fonts (+1000 fonts) available on the Google Fonts website for you to use.</p>
          
          <p className="font-medium mt-5">Find the font you like:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/fonts.png" alt="Fonts example" />
        </div>
      </div>
    )
  }
  else if (item === "forum-icons") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Forum Icons</h1>
        <div className="pt-5">
          <p className="text-sm">With this option, you can change icons manually from the Zeina panel</p>
          
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/forums-icons.png" alt="Forum Icons example" />
        
          <p className="text-sm mt-4"><span className="font-semibold">Default:</span> <span className="text-gray-600">Icons that are integrated into the theme.</span></p>
          <p className="text-sm mt-2"><span className="font-semibold">Images:</span> <span className="text-gray-600">Selects icons from the images folder.</span></p>
          <p className="text-sm mt-2">You will find the image folder on this path: <span className="text-blue-600">styles/zeina/theme/images/icons</span> </p>
          <p className="text-sm mt-2">You can replace existing images with new images (GIF, PNG, SVG)</p>
          
          <p className="text-sm mt-1">After adding new images, you must set their extension from the Zeina panel <span className="font-semibold">(Forum Image Type)</span></p>
          <img className="rounded-xl shadow border mt-4 w-auto" src="./assets/images/forums-icons-2.png" alt="Forum Icons 2 example" />

          <p className="text-sm mt-4"><span className="font-semibold">Custom:</span> <span className="text-gray-600">You can easily replace old icons with new ones from the Zeina panel.</span></p>
          <p className="text-sm mt-2">Choose the type of forum whose icon you want to change and choose the new icon.</p>
          <p className="text-sm mt-2 text-red-600">* Note: In this option, you must set all icons yourself.</p>
          <img className="rounded-xl shadow border mt-4 w-auto" src="./assets/images/forums-icons-3.png" alt="Forum Icons 2 example" />
        </div>
      </div>
    )
  }
  else if (item === "scrollbar") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Colorful Scrollbar</h1>
        <div className="pt-5">
          <p className="text-sm">This option makes the sliders thinner and more colorful.</p>
          
          <p className="font-medium mt-5">Example:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/scrollbar.png" alt="Fonts example" />
        </div>
      </div>
    )
  }
  else if (item === "scroll") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Scroll-To-Top Button</h1>
        <div className="pt-5">
          <p className="text-sm">Enable/Disable scroll to top button.</p>
          
          <p className="font-medium mt-5">Example:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/scroll-to-top.png" alt="Fonts example" />
        </div>
      </div>
    )
  }
  else if (item === "effects") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Animations and effects</h1>
        <div className="pt-5">
          <p className="text-sm"><span className="font-semibold">Fade:</span> <span className="text-gray-600">The menu appears and disappears without moving.</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Zoom:</span> <span className="text-gray-600">The menu will grow when it appears and shrink when it is closed.</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Slide:</span> <span className="text-gray-600">The menu will move from bottom to top when it appears.</span></p>
          <p className="text-sm mt-5"><span className="font-semibold">Ripple Effect:</span> <span className="text-gray-600">A ripple effect that starts at the button click position and extends outward.</span></p>

          <p className="font-medium mt-6">Example (Fade):</p>
          <video className="rounded-xl shadow border mt-3 w-auto" autoPlay={true} muted loop>
            <source src="./assets/images/fade.mp4" type="video/mp4" />
          </video>

          <p className="font-medium mt-10">Example (Zoom):</p>
          <video className="rounded-xl shadow border mt-3 w-auto" autoPlay={true} muted loop>
            <source src="./assets/images/zoom.mp4" type="video/mp4" />
          </video>

          <p className="font-medium mt-10">Example (Slide):</p>
          <video className="rounded-xl shadow border mt-3 w-auto" autoPlay={true} muted loop>
            <source src="./assets/images/slide.mp4" type="video/mp4" />
          </video>
          
          <p className="font-medium mt-10">Example (Ripple Effect):</p>
          <video className="mt-3 w-auto" autoPlay={true} muted loop>
            <source src="./assets/images/ripple.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    )
  }
};

export default General;
