import { useLayoutEffect } from "react";
import MainRouter from "./router";
import { useLocation } from "react-router-dom"


function App() {

  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <MainRouter />
    </>
  );
}

export default App;
