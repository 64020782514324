import React from "react";

const Headers = ({ item }) => {
  if (item === "image") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Header Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Header Image / Video</h1>
        <div className="pt-5">
          <p className="text-sm">Use this option to upload an image or video.</p>

          <p className="text-sm mt-4"><span className="font-semibold">Accepted extensions (Images):</span> <span className="text-gray-600">png, jpeg, jpg, gif, svg, webp.</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Accepted extensions (Videos):</span> <span className="text-gray-600">mp4, mov, wmv, avi.</span></p>
        
          <p className="font-medium mt-5">Header Image / Video:</p>
          <video className="rounded-xl shadow border mt-3 w-auto mx-auto" controls>
            <source src="./assets/images/header-image.mp4"></source>
          </video>
        </div>
      </div>
    );
  }
  else if (item === "overlay") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Header Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Header Overlay</h1>
        <div className="pt-5">
          <p className="text-sm">Use this option to add overlay (Single color / Gradient color) on the header image.</p>

          <p className="font-medium mt-5">Header Overlay:</p>
          <video className="rounded-xl shadow border mt-3 w-auto mx-auto" controls>
            <source src="./assets/images/overlay.mp4"></source>
          </video>
        </div>
      </div>
    );
  }
  else if (item === "height") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Header Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Header Height</h1>
        <div className="pt-5">
          <p className="text-sm">Set the height of the header image.</p>
          <p className="text-sm mt-2 text-blue-600">* You can adjust a specific height for the mobile phone mode.</p>
          
          <p className="font-medium mt-5">Example:</p>
          <video className="rounded-xl shadow border mt-3 w-auto mx-auto" controls>
            <source src="./assets/images/height.mp4"></source>
          </video>
        </div>
      </div>
    );
  }
  else if (item === "mobile") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Header Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Crop header image in phone size</h1>
        <div className="pt-5">
          <p className="text-sm">When this option is activated, an image smaller than the original image will be generated and loaded instead of the original image when browsing the website from a mobile phone.</p>
          <p className="text-red-500 text-sm mt-2 font-medium">* This option does not work with images in svg format.</p>
          <p className="font-medium mt-5">Example:</p>
          <p className="text-gray-600 text-sm mt-1">When <span className="text-blue-600 font-medium">Mobile Size</span> option is activated, a cropped header image will be created for display on screens with a width less than 768px. Thus, we were able to save more than 900 kilobytes, which increases the speed of browsing the website on mobile phones.</p>
          <div class="md:grid grid-cols-3 gap-4 mt-2 max-md:space-y-4">
            <div class="col-span-2 md:pe-14">
              <img class="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/header-size.png" />
              <div class="mt-3 font-medium text-sm text-center text-blue-600">Desktop Screen</div>
              <div class="mt-1 font-medium text-sm text-center text-gray-600">(1920 x 1080) 1.13 MB</div>
            </div>
            <div class="max-md:w-80 mx-auto">
              <img class="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/header-size-sm.png" />
              <div class="mt-3 font-medium text-sm text-center text-blue-600">Mobile Screen</div>
              <div class="mt-1 font-medium text-sm text-center text-gray-600">(768 x 432) 212 KB</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if (item === "webp") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Header Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Header image in WebP format</h1>
        <div className="pt-5">
          <p className="text-sm">When you activate this option, a smaller image will be created than the original image to speed up website browsing. In addition, Google prefers this format for better performance and SEO.</p>
          <p className="text-gray-600 text-sm mt-3"><span className="text-blue-600 font-medium">Note:</span> An image will also be created for the mobile phone in WebP format if its option is activated <span className="font-semibold">(Header image in mobile size)</span>.</p>
          <p className="font-medium mt-5">Example:</p>
          <p className="text-gray-600 text-sm mt-1">In this example, we will convert the image to the new format. <span className="text-blue-600 font-medium">Note the difference in image size</span></p>
          <div className="md:grid grid-cols-2 gap-2 mt-2 max-md:space-y-4">
            <div>
              <img className="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/header-size.png" />
              <div className="mt-3 font-medium text-sm text-center text-blue-600">Original header image</div>
              <div className="mt-1 font-medium text-sm text-center text-gray-600">Size: 1.13 MB</div>
            </div>
            <div>
              <img className="rounded-xl shadow border mt-3 w-auto mx-auto" src="./assets/images/header-size-webp.png" />
              <div className="mt-3 font-medium text-sm text-center text-blue-600">WebP Format</div>
              <div className="mt-1 font-medium text-sm text-center text-gray-600">Size: 16 KB</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if (item === "parallax") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Header Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Parallax Header</h1>
        <div className="pt-5">
          <p className="text-sm">The header image is pinned to the top of the whole ContentView.</p>

          <p className="font-medium mt-5">Parallax Header (Image):</p>
          <video className="rounded-xl shadow border mt-3 w-auto mx-auto" controls>
            <source src="./assets/images/parallax.mp4"></source>
          </video>

          <p className="font-medium mt-10">Parallax Header (Video):</p>
          <video className="rounded-xl shadow border mt-3 w-auto mx-auto" controls>
            <source src="./assets/images/parallax2.mp4"></source>
          </video>
        </div>
      </div>
    );
  }
}

export default Headers;
