import React from "react";

const Theme = () => {
  return (
    <div>
      <p className="mb-3 text-sm font-semibold text-blue-600">Getting Started</p>
      <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Install Zeina Theme</h1>
      <ol className="relative space-y-2 doc-installation-step mt-5">
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="text-gray-800">
            Log in to cPanel and click on the <span className="text-blue-600 mx-0.5">File Manager</span>.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/cpanel-2.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            Open <span className="text-blue-600 mx-0.5">public_html</span> folder.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-1.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            Open <span className="text-blue-600 mx-0.5">ext</span> folder.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-2.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            Upload <span className="text-blue-600 mx-0.5">ext.zip</span> file.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-3.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            After uploading, extract the <span className="text-blue-600 mx-0.5">ext.zip</span> file.
          </p>
          <p className="text-gray-600 text-sm">Don't forget to delete the zip files after unzipping</p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-4.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            From <span className="text-blue-600 mx-0.5">public_html</span> folder, open <span className="text-blue-600 mx-0.5">styles</span> folder.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-5.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            Upload and extract the <span className="text-blue-600 mx-0.5">style.zip</span> file.
          </p>
          <p className="text-gray-600 text-sm">Don't forget to delete the zip files after unzipping</p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-6.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            Go to phpBB control panel.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-7.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            From <span className="text-blue-600 mx-0.5">Customise</span> tab, enable <span className="text-blue-600 mx-0.5">Zeina Theme</span> extension.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-8.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            From <span className="text-blue-600 mx-0.5">Style Management</span> choose <span className="text-blue-600 mx-0.5">Install Styles</span>
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-9.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            After installation, go back to the list of styles and click on <span className="text-blue-600 mx-0.5">Details</span>
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-10.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            Make sure the style is activated and default.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-11.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            Go to <span className="text-blue-600 mx-0.5">Board settings</span> from <span className="text-blue-600 mx-0.5">General</span> tab and under <span className="text-blue-600 mx-0.5">Board style</span> select Zeina as default style.
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-12.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="font-medium">
            Congratulations, You are ready now. Enjoy!
          </p>
          <img className="rounded-lg shadow border max-w-full w-fit mt-4" src="./assets/images/install-zeina-13.png" />
        </li>
      </ol>
    </div>
  );
};

export default Theme;
