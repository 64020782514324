import React from "react";

const Footer = () => {
  return (
    <footer className='w-full mt-8'>
      <p className="text-center border-t text-sm text-gray-600 py-5">Copyrights <a href="https://leenoz.com" className="hover:text-blue-600">Leenoz</a> &#169; {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
