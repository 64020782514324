import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const Custom = ({ item }) => {
  if (item === "css-code") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Custom Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Custom CSS</h1>
        <div className="pt-5">
          <p className="text-gray-600 text-sm">Customize your theme by adding some CSS code to the page head.</p>

          <p className="font-medium mt-5">Tutorial:</p>
          <p className="text-gray-600 text-sm mt-1">Copy and paste this code into your custom CSS editor and save the settings.</p>
          <pre className="mt-3 bg-gray-100 border border-gray-200 text-sm leading-6 flex overflow-auto rounded-xl">
            <code className="p-5 whitespace-break-spaces"><div><span className="text-red-500">.breadcrumb</span> <span className="text-violet-500">li:last-child a</span> <span className="text-slate-800">&#123;</span><div>&nbsp;&nbsp;color: <span className="text-red-500">rgb</span>(<span className="text-red-400">var</span>(--color-text));</div><div><span className="text-slate-800">&#125;</span></div></div><div><span className="text-red-500">.dark</span> <span className="text-red-500">.breadcrumb</span> <span className="text-violet-500">li:last-child a</span> <span className="text-slate-800">&#123;</span><div>&nbsp;&nbsp;color: <span className="text-red-500">rgb</span>(<span className="text-red-400">var</span>(--color-text-dark));</div><div><span className="text-slate-800">&#125;</span></div></div></code>
          </pre>
          <br />
          <p className="font-medium mt-5">Result:</p>
          <p className="text-gray-600 text-sm mt-1">In this example, the style color will be given to the current navigation link as in the image</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/custom-css.png" alt="Custom CSS Code" />
        </div>
      </div>
    );
  }
  else if (item === "js-code") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Custom Settings</p>
        <h1 className="block text-2xl font-bold text-gray-800 pb-5 border-b">Custom Javascript</h1>
        <div className="pt-5">
          <div className="mb-6 bg-yellow-50 border border-yellow-500 text-sm text-gray-500 rounded-lg p-5">
            <div className="flex">
              <Icon className="text-yellow-700" icon="ph:warning-bold" width="18" height="18" />
              <div className="ms-3">
                <h3 className="text-yellow-700 font-semibold dark:font-medium dark:text-white">
                  Warning
                </h3>
                <p className="mt-2 text-gray-800 dark:text-neutral-400">
                  You must be careful when writing codes. A simple mistake may cause the website to stop working.
                </p>
              </div>
            </div>
          </div>

          <p className="font-medium">Tutorial:</p>
          <p className="text-gray-600 text-sm mt-1">Copy and paste this code into your custom Javascript editor and save the settings.</p>
          <pre className="mt-3 bg-gray-100 border border-gray-200 text-sm leading-6 flex overflow-auto rounded-xl">
            <code className="p-5 whitespace-break-spaces"><div><span className="text-red-500">alert</span><span className="text-slate-800">(</span><span className="text-cyan-700">"Hello World!"</span><span className="text-slate-800">);</span></div></code>
          </pre>
          
          <p className="font-medium mt-5">Result:</p>
          <img className="rounded-lg shadow border mt-3 w-auto" src="./assets/images/custom-js.png" alt="Custom javascript Code" />
        
          <div className="mt-10 bg-blue-50 border border-blue-500 text-sm text-gray-500 rounded-lg p-5 dark:bg-blue-600/10 dark:border-blue-700">
            <div className="flex">
              <Icon className="text-blue-600" icon="ph:info-bold" width="18" height="18" />
              <div className="ms-3">
                <h3 className="text-blue-600 font-semibold dark:font-medium dark:text-white">
                  Inforamtion
                </h3>
                <p className="mt-2 text-gray-800 dark:text-neutral-400">
                  All Javascript code will not be executed in preview mode. To preview the changes, save the settings and go to the home page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Custom;