import React from "react";
import { Icon } from '@iconify/react';
import { NavLink } from "react-router-dom"

function Menu({ menuState }) {
  var menu_data = [
    {
      id: 1,
      label: 'Getting Started',
      icon: ['lucide:rocket', 16],
      submenu: [
        {id: 1, label: 'Install phpBB', link: '/install-phpbb'},
        {id: 2, label: 'Install Zeina Theme', link: '/install-zeina-theme'},
        {id: 3, label: 'Install compatible extension', link: '/install-extenstions'},
      ]
    }, {
      id: 2,
      label: 'General Settings',
      icon: ['lucide:settings', 16],
      submenu: [
        {id: 1, label: 'Logo', link: '/logo'},
        {id: 2, label: 'Favicon', link: '/favicon'},
        {id: 3, label: 'Page Layout', link: '/page-layout'},
        {id: 4, label: 'Theme Mode', link: '/theme-mode'},
        {id: 5, label: 'Theme Color', link: '/theme-color'},
        {id: 6, label: 'Font Style', link: '/font-style'},
        {id: 7, label: 'Forum Icons', link: '/forum-icons'},
        {id: 8, label: 'Colorful Scrollbar', link: '/colorful-scrollbar'},
        {id: 9, label: 'Scroll To Top Button', link: '/scroll-to-top-button'},
        {id: 10, label: 'Animations & Effects', link: '/animations-and-effects'},
      ]
    }, {
      id: 3,
      label: 'Navbar Settings',
      icon: ['ic:round-menu-open', 18],
      submenu: [
        {id: 1, label: 'Navbar Items', link: '/navbar-items'},
        {id: 2, label: 'Fixed Navbar', link: '/fixed-navbar'},
        {id: 3, label: 'Quick Login Dropdown', link: '/quick-login-dropdown'},
      ]
    }, {
      id: 4,
      label: 'Header Settings',
      icon: ['akar-icons:image', 16],
      submenu: [
        {id: 1, label: 'Header Image / Video', link: '/header-image'},
        {id: 2, label: 'Header Overlay', link: '/header-overlay'},
        {id: 3, label: 'Header Height', link: '/header-height'},
        {id: 4, label: 'Crop image in phone size', link: '/mobile-size'},
        {id: 5, label: 'Header image in WebP format', link: '/webp-format'},
        {id: 6, label: 'Parallax Header', link: '/parallax-header'},
      ]
    }, {
      id: 5,
      label: 'Panels Settings',
      icon: ['fluent:panel-left-header-32-regular', 18],
      submenu: [
        {id: 1, label: 'Panel Image Settings', link: '/panel-head-image'},
        {id: 2, label: 'Panel Head Color', link: '/panel-head-color'},
        {id: 2, label: 'Panel Head Overlay', link: '/panel-head-overlay'},
        {id: 3, label: 'Collapsible Panel', link: '/collapsible-panel'},
        {id: 4, label: 'Last Visit & Current Time', link: '/last-visit-and-current-time'},
        {id: 5, label: 'Sidebars', link: '/sidebars'},
        {id: 6, label: 'Profile View', link: '/profile-view'},
        {id: 7, label: 'Code Style', link: '/code-style'},
      ]
    }, {
      id: 6,
      label: 'Footer Settings',
      icon: ['ph:link-bold', 16],
      submenu: [
        {id: 1, label: 'Footer Items', link: '/footer-items'},
        {id: 2, label: 'Social Media Items', link: '/social-media-items'},
        {id: 3, label: 'Custom Copyright', link: '/custom-copyright'},
      ]
    }, {
      id: 7,
      label: 'Custom Settings',
      icon: ['ph:code-bold', 16],
      submenu: [
        {id: 1, label: 'Custom CSS', link: '/custom-css-codes'},
        {id: 2, label: 'Custom Javascript', link: '/custom-js-codes'},
      ]
    }
  ];

  return (
    <>
      <div id="application-sidebar" className={(menuState ? 'open ' : '') + `z-10 w-[300px] overflow-auto fixed sm:top-[73px] top-[120px] bottom-0 lg:translate-x-0 -translate-x-full bg-white transition-all duration-300`}>
        <nav className="py-6 flex flex-col flex-wrap pe-4">
          <ul className="space-y-5">
            {menu_data.map((item) => (
              <li className={item.submenu ? '' : 'flex items-center before:border-b before:w-4 before:start-0 before:block'} key={item.id}>
                {item.submenu ? (
                  <>
                    <span className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 font-semibold rounded-lg">
                      <Icon icon={item.icon[0]} width={item.icon[1]} height={item.icon[1]} mode="svg" />
                      {item.label}
                    </span>
                    <ul className="pt-1 ms-4 relative before:border-s before:absolute before:start-0 before:rounded-e-2xl before:h-full">
                      {item.submenu.map((subitem) => (
                        <li key={subitem.id} className="flex items-center before:border-b before:w-4 before:start-0 before:block">
                          <NavLink className={({ isActive }) => (isActive ? "active " : "") +  "inline-block grow ms-2 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"} to={subitem.link} end>
                            {subitem.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : ''}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Menu;
